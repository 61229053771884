<template>
  <div class="animated fadeIn">
    <loading v-if="vLoading" />
    <v-col cols="12">
      <div>
        <div style="background-color: transparent !important">
          <v-row>
            <v-col></v-col>
            <v-col cols="12" sm="6" md="6" lg="6" xl="6">
              <v-btn
                class="primary-btn pa-5"
                style="float: left"
                @click="
                  addFileDialog = true;
                  (file = []),
                    (myFile.isUploaded = false),
                    (myFile.showProgress = false);
                "
              >
                <v-icon left>mdi-file-upload-outline</v-icon>
                <h6 class="mt-1">آپلود فایل جدید</h6>
              </v-btn>
            </v-col>
          </v-row>
        </div>

        <div>
          <div>
            <v-text-field
              v-model="Filter"
              prepend-inner-icon="mdi-magnify"
              label="جستجو"
              single-line
              hide-details
              filled
              rounded
              clearable
              class="text-right w-80 mt-2 mb-2 search-input"
            ></v-text-field>
            <b-table
              responsive
              show-empty
              :fields="Fields"
              :items="files"
              empty-text="فایلی برای نمایش وجود ندارد"
              empty-filtered-text="فایلی برای نمایش وجود ندارد"
              :filter="Filter"
              :current-page="CurrentPage"
              :per-page="PerPage"
              @filtered="onFiltered"
              @row-clicked="onRowSelected"
              tbody-tr-class="hover-tr"
            >
              <template v-slot:head()="data">
                <div style="text-align: center; vertical-align: middle">
                  {{ data.label }}
                </div>
              </template>
              <template v-slot:cell()="data">
                <div style="text-align: center; vertical-align: middle">
                  {{ data.value }}
                </div>
              </template>
              <template v-slot:cell(name)="data">
                <div
                  style="text-align: center; vertical-align: middle"
                  dir="ltr"
                >
                  {{
                    data.value.length > 40
                      ? data.value.slice(0, 40) + "..."
                      : data.value
                  }}
                </div>
              </template>
              <template v-slot:cell(description)="data">
                <div
                  style="text-align: center; vertical-align: middle"
                  v-if="data.value.length"
                >
                  <v-tooltip
                    right
                    max-width="600"
                    v-if="deviceType != 'mobile'"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <span v-bind="attrs" v-on="on">
                        {{
                          data.value.length > 20
                            ? data.value.slice(0, 20) + "..."
                            : data.value
                        }}
                      </span>
                    </template>
                    <p class="text-right p-2 text-justify">{{ data.value }}</p>
                  </v-tooltip>
                  <p v-else>
                    {{
                      data.value.length > 20
                        ? data.value.slice(0, 20) + "..."
                        : data.value
                    }}
                  </p>
                </div>
                <div style="text-align: center; vertical-align: middle" v-else>
                  <span> - </span>
                </div>
              </template>
              <template v-slot:cell(type)="data">
                <div style="text-align: center; vertical-align: middle">
                  {{
                    data.value.length > 40
                      ? data.value.slice(0, 40) + "..."
                      : data.value
                  }}
                </div>
              </template>
              <template v-slot:cell(size)="data">
                <div style="text-align: center; vertical-align: middle">
                  <span dir="ltr">{{ data.value }}</span>
                </div>
              </template>
              <template v-slot:cell(operation)="data">
                <div style="text-align: center; vertical-align: middle">
                  <a
                    class="v-btn primary-btn px-5"
                    :href="data.item.url"
                    target="_blank"
                  >
                    مشاهده فایل
                  </a>
                  <v-btn
                    v-if="data.item.description != ''"
                    class="primary-btn py-2 px-3 ma-1"
                    @click="
                      editDescriptionDialog = true;
                      selected = JSON.parse(JSON.stringify(data.item));
                    "
                    target="_blank"
                  >
                    ویرایش توضیح
                  </v-btn>
                  <v-btn
                    v-else
                    class="primary-btn py-2 px-4 ma-1"
                    @click="
                      editDescriptionDialog = true;
                      selected = JSON.parse(JSON.stringify(data.item));
                    "
                    target="_blank"
                  >
                    افزودن توضیح
                  </v-btn>
                </div>
              </template>
              <template v-slot:cell(index)="data">
                <div style="text-align: center; vertical-align: middle">
                  {{ data.index + PerPage * (CurrentPage - 1) + 1 }}
                </div>
              </template>
              <div slot="table-busy" class="text-center primary--text my-2">
                <v-progress-circular
                  indeterminate
                  color="primary"
                  class="align-middle"
                ></v-progress-circular>
              </div>
            </b-table>
            <v-pagination
              v-model="CurrentPage"
              :length="Math.ceil(files.length / PerPage)"
              :total-visible="5"
              prev-icon="arrow_back"
              next-icon="arrow_forward"
              style="float: center"
            ></v-pagination>
            <v-select
              label="تعداد در هر صفحه:"
              style="width: 150px"
              v-model="PerPage"
              :items="perPageOptions"
              item-text="text"
              item-value="value"
            ></v-select>
          </div>
        </div>
      </div>
    </v-col>
    <v-dialog v-model="addFileDialog" width="600">
      <v-card class="pa-3" style="overflow-x: hidden">
        <v-card-title class="mb-2">
          <h4>آپلود فایل جدید</h4>
          <v-spacer></v-spacer>
          <v-icon
            color="red"
            class="mb-2 close-icon"
            @click="addFileDialog = false"
            >mdi-close</v-icon
          >
        </v-card-title>
        <v-card-text>
          <v-file-input
            @change="addFile(file)"
            v-model="file"
            outlined
            dense
            prepend-inner-icon="upload_file"
            prepend-icon=""
            class="file-input"
            label="بارگذاری فایل"
          >
          </v-file-input>
          <v-progress-linear
            rounded
            v-if="myFile.showProgress"
            height="10"
            :value="myFile.uploadPercentage"
            class="mb-4 mt-0"
          >
          </v-progress-linear>
          <v-row>
            <div
              v-if="myFile.isUploaded"
              color="green"
              class="mt-3 mb-1 pa-3"
              style="font-size: 16px"
            >
              فایل با موفقیت بارگذاری شد.
            </div>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="fileDialog" width="600">
      <v-card class="pa-3">
        <v-card-title class="mb-2">
          <h4 class="primary--text font-weight-bold">جزییات فایل</h4>
          <v-spacer></v-spacer>
          <v-icon
            color="red"
            class="mb-2 close-icon"
            @click="fileDialog = false"
            >mdi-close</v-icon
          >
        </v-card-title>
        <v-card-text>
          <v-row class="mt-3">
            <v-col cols="12" sm="3" md="3" lg="3" xl="3" class="text-right">
              <span class="h2 primary--text font-weight-bold"> نام فایل:</span>
            </v-col>
            <v-col cols="12" sm="9" md="9" lg="9" xl="9" class="text-right">
              <span class="h2 text-left" dir="ltr"> {{ selected.name }}</span>
            </v-col>
          </v-row>
          <v-row class="mt-3">
            <v-col cols="12" sm="3" md="3" lg="3" xl="3" class="text-right">
              <span class="h2 primary--text font-weight-bold"> حجم فایل:</span>
            </v-col>
            <v-col cols="12" sm="9" md="9" lg="9" xl="9" class="text-right">
              <span dir="ltr" class="h2 text-left"> {{ selected.size }}</span>
            </v-col>
          </v-row>
          <v-row class="mt-3">
            <v-col cols="12" sm="3" md="3" lg="3" xl="3" class="text-right">
              <span class="h2 primary--text font-weight-bold"> نوع فایل:</span>
            </v-col>
            <v-col cols="12" sm="9" md="9" lg="9" xl="9" class="text-right">
              <span class="h2 text-left"> {{ selected.type }}</span>
            </v-col>
          </v-row>
          <v-row class="mt-3">
            <v-col cols="12" sm="3" md="3" lg="3" xl="3" class="text-right">
              <span class="h2 primary--text font-weight-bold"> لینک فایل:</span>
            </v-col>
            <v-col cols="12" sm="9" md="9" lg="9" xl="9" class="text-right">
              <a
                class="h2 text-left"
                target="_blank"
                style="color: #0099cc !important"
                :href="selected.url"
              >
                دانلود</a
              >
            </v-col>
          </v-row>
          <v-row class="mt-3">
            <v-col cols="12" sm="3" md="3" lg="3" xl="3" class="text-right">
              <span class="h2 primary--text font-weight-bold">
                توضیحات فایل:</span
              >
            </v-col>
            <v-col cols="12" sm="9" md="9" lg="9" xl="9">
              <p class="text-right text-justify">
                {{
                  selected.description
                    ? selected.description
                    : "توضیحی ثبت نشده است."
                }}
              </p>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="addDescriptionDialog" width="600">
      <v-card class="pa-3">
        <v-card-title class="mb-2">
          <h4>توضیحات فایل</h4>
          <v-spacer></v-spacer>
          <v-icon
            color="red"
            class="mb-2 close-icon"
            @click="addDescriptionDialog = false"
            >mdi-close</v-icon
          >
        </v-card-title>
        <v-card-text class="time-row">
          <v-textarea v-model="fileDescription" outlined label="توضیحات فایل">
          </v-textarea>
        </v-card-text>

        <v-card-actions class="mt-0 pt-0">
          <v-row>
            <v-col class="text-left">
              <v-btn class="primary-btn" @click="newFileDescription()"
                >ثبت توضیح</v-btn
              >
              <v-btn
                class="red-btn mx-1"
                @click="
                  addDescriptionDialog = false;
                  $emit('getInfo');
                "
                >بعدا اضافه میکنم</v-btn
              >
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="editDescriptionDialog" width="600">
      <v-card class="pa-3">
        <v-card-title class="mb-2">
          <h4>توضیحات فایل</h4>
          <v-spacer></v-spacer>
          <v-icon
            color="red"
            class="mb-2 close-icon"
            @click="editDescriptionDialog = false"
            >mdi-close</v-icon
          >
        </v-card-title>
        <v-card-text class="time-row">
          <v-textarea
            v-model="selected.description"
            outlined
            label="توضیحات فایل"
          >
          </v-textarea>
        </v-card-text>

        <v-card-actions class="mt-0 pt-0">
          <v-row>
            <v-col class="text-left">
              <v-btn class="primary-btn" @click="editFileDescription()"
                >ثبت توضیح</v-btn
              >
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
export default {
  props: ["files"],
  data() {
    return {
      deviceType: "",
      CurrentPage: 1,
      PerPage: 10,
      perPageOptions: [
        { value: 10, text: "10" },
        { value: 25, text: "25" },
        { value: 50, text: "50" },
        { value: 100, text: "100" },
      ],
      TotalRows: "",
      drName: "",
      nurseName: "",
      Filter: "",
      Busy: true,
      Fields: [
        { key: "index", label: "#" },
        { key: "name", label: "نام فایل" },
        { key: "size", label: "حجم" },
        { key: "type", label: "نوع فایل" },
        { key: "description", label: "توضیحات" },
        { key: "operation", label: "مشاهده" },
      ],
      Items: [],
      addFileDialog: false,
      fileDialog: false,
      role: "",
      showProgress: false,
      uploadButDisable: false,
      variant: "info",
      striped: true,
      max: 100,
      uploadPercentage: 0,
      isUploaded: false,
      file: [],
      reportFiles: [],
      request: {},
      myFile: new Object({
        uploadPercentage: 0,
      }),
      fileBusy: false,
      fileFilter: "",

      myFile: new Object({
        uploadPercentage: 0,
      }),
      selected: {},
      fileDescription: "",
      fileId: "",
      addDescriptionDialog: false,
      editDescriptionDialog: false,
    };
  },

  methods: {
    addFile(file) {
      // NOTE (m-myUpload) to upload files
      this.myFile.showProgress = true;
      this.myFile.variant = "info";
      this.myFile.uploadPercentage = 0;
      this.myFile.isUploaded = false;
      this.myFile.uploadButDisable = true;
      var formData = new FormData();
      formData.append(file.name, file);

      this.$http
        .post(
          this.baseUrl +
            "/clinic/hr/employee/upload?userId=" +
            this.$route.params.id,
          formData,
          {
            headers: {
              Authorization: "Bearer: " + localStorage.getItem("token"),
            },
            onUploadProgress: ((progressEvent) => {
              this.myFile.uploadPercentage =
                Math.round((progressEvent.loaded * 100) / progressEvent.total) -
                1;
            }).bind(this),
          }
        )
        .then((d) => {
          if (d.status == 201) {
            this.myFile.isUploaded = true;
            this.myFile.uploadPercentage = 100;
            this.myFile.striped = false;
            this.myFile.variant = "success";
            this.myFile.uploadButDisable = false;
            this.addFileDialog = false;

            this.fileId = d.data;
            this.addDescriptionDialog = true;
            //this.$emit("getInfo");
          }
        })
        .catch((err) => {
          this.addFileDialog = false;

          this.toast("خطا: " + err.response.data, "error");
        });
    },
    newFileDescription() {
      this.$http
        .post(
          this.baseUrl + "/clinic/uploads/addDescription",
          {
            description: this.fileDescription,
            fileId: this.fileId,
          },
          {
            headers: {
              Authorization: "Bearer: " + localStorage.getItem("token"),
            },
          }
        )
        .then((res) => {
          if (res.status == 200) {
            this.toast(res.data, "success");
            this.addDescriptionDialog = false;
            this.$emit("getInfo");
            this.fileDescription = "";
          } else {
            this.toast("خطا: مشکلی پیش آمده. مجددا امتحان کنید.", "error");
          }
        })
        .catch((err) => {
          this.toast("خطا: " + err.response.data, "error");
        });
    },
    editFileDescription() {
      this.$http
        .post(
          this.baseUrl + "/clinic/uploads/addDescription",
          {
            description: this.selected.description,
            fileId: this.selected.id,
          },
          {
            headers: {
              Authorization: "Bearer: " + localStorage.getItem("token"),
            },
          }
        )
        .then((res) => {
          if (res.status == 200) {
            this.toast(res.data, "success");
            this.editDescriptionDialog = false;
            this.$emit("getInfo");
          } else {
            this.toast("خطا: مشکلی پیش آمده. مجددا امتحان کنید.", "error");
          }
        })
        .catch((err) => {
          this.toast("خطا: " + err.response.data, "error");
        });
    },
    onRowSelected(item) {
      this.selected = JSON.parse(JSON.stringify(item));
      this.fileDialog = true;
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.TotalRows = filteredItems.length;
      this.CurrentPage = 1;
    },
  },
  mounted() {
    if (window.screen.width < 950) {
      this.deviceType = "mobile";
    } else {
      if (document.body.clientWidth < 950) {
        this.deviceType = "mobile";
      } else {
        this.deviceType = "desktop";
      }
    }
    this.role = localStorage.getItem("role");
    this.TotalRows = this.files.length;
    this.CurrentPage = 1;
  },
};
</script>
